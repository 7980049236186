/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { FaFileDownload } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Grid,
  Typography,
  InputBase,
  TextField,
  Button,
  Box,
  MenuItem,
} from '@material-ui/core'

import axios from 'axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import { SNACKBAR } from '../../main/MainActions'
import colors from '../../../assets/colors'
import config from '../../../config'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import classNames from 'classnames'
import NumberFormatCustom from '../../components/NumberFormat'
import Icon from '@mdi/react'
import { mdiInvoiceTextCheckOutline } from '@mdi/js'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import ReactInputMask from 'react-input-mask'
import { isCpfCnpjValid } from '../../../util/cpf_cnpj_validator'
import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import { fetchAttendanceInit } from '../AttendanceActions'

const useStyles = makeStyles(theme => ({
  subTitle: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '.5rem',
    width: '50%',
    height: '70%',
    border: `2px solid ${colors.secondary}`,
  },
  title: {
    fontSize: '1.5rem',
    fontFamily: 'sans-serif',
    textAlign: 'center',
    color: colors.primary,
  },
  tableContainer: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  tableHeadCell: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  container: {
    width: '470px',
  },
}))

const EletronicInvoice = props => {
  const classes = useStyles()

  const history = useHistory()
  const dispatch = useDispatch()
  const filterClasses = filterStylesComponents()

  const [attendance, setAttendance] = useState()

  const { id } = props.match.params

  const [mask, setMask] = useState('999.999.999-99')

  const [form, setForm] = useState({
    description: '',
    value: '',
    cpf_cnpj: '',
    client_name: '',
    client_cep: '',
    client_city: '',
    client_uf: '',
    client_street: '',
    client_neighborhood: '',
    client_number: '',
    client_complement: '',
    client_phone: '',
    client_email: '',
    iss_retido: '',
    aliquota_iss: '',
  })

  const [cpfCnpj, setCpfCnpj] = useState({
    error: false,
    text: '',
  })

  const fetchAttedance = async () => {
    try {
      const resp = await axios.get(`${config.API_URL}/attendance/${id}`)

      setAttendance(resp?.data?.data)
    } catch (e) {
      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: e?.response?.data?.message,
        },
      })
    }
  }

  const handleClose = () => {
    history.push('/attendance')
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleInitDescription = () => {
    const { services } = attendance

    if (Array.isArray(services)) {
      const { client } = attendance
      let servicesDescription = ''

      services?.map(serviceItem => {
        servicesDescription += `- ${serviceItem?.name} ${' '}`
      })

      setForm({
        ...form,
        description: servicesDescription,
        value: attendance?.total_with_discount,
        cpf_cnpj: client?.cpf_cnpj,
        client_name: client?.name,
        client_city: client?.city,
        client_uf: client?.uf,
        client_street: client?.street,
        client_neighborhood: client?.neighborhood,
        client_number: client?.number,
        client_cep: client?.cep,
        client_complement: client?.complement,
        client_phone: client?.phone,
        client_email: client?.email,
      })

      if (client?.cpf_cnpj?.length >= 15) {
        setMask('99.999.999/9999-99')
      } else {
        setMask('999.999.999-999')
      }
    }
  }

  const handleChangeCpf = e => {
    const { name, value } = e.target

    if (value.length >= 15) {
      setMask('99.999.999/9999-99')
    } else {
      setMask('999.999.999-999')
    }

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleValidCpfCnpj = e => {
    const { name, value } = e.target

    if (value.length == 14) {
      if (isCpfCnpjValid(value)) {
        setCpfCnpj({
          ...cpfCnpj,
          error: false,
          text: '',
        })
      } else {
        setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CPF inválido',
        })
      }
    } else if (value.length == 18) {
      if (isCpfCnpjValid(value)) {
        setCpfCnpj({
          ...cpfCnpj,
          error: false,
          text: '',
        })
      } else {
        setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CNPJ inválido',
        })
      }
    } else {
      setCpfCnpj({
        ...cpfCnpj,
        error: false,
        text: 'Digite um CPF ou CNPJ válido',
      })
    }
  }

  const handleSearchCep = async cep => {
    const currentCep = cep?.replace(/\D/g, '')

    try {
      if (currentCep?.length == 8) {
        const resp = await axios
          .get(`https://viacep.com.br/ws/${currentCep}/json/`)
          .then(response => response?.data)

        setForm({
          ...form,
          client_city: resp?.localidade,
          client_uf: resp?.uf,
          client_street: resp?.logradouro,
          client_neighborhood: resp?.bairro,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (cpfCnpj?.error) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: 'CNPJ Inválido',
        },
      })
    }

    try {
      const resp = await axios.post(
        `${config.API_URL}/attendance/nfse/${id}`,
        form
      )

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: {
          message: 'NFSe gerada com sucesso, aguarde o processamento!',
        },
      })

      dispatch(fetchAttendanceInit())
    } catch (e) {
      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: e?.response?.data?.error,
        },
      })
    }
  }

  useEffect(() => {
    if (id) {
      fetchAttedance()
    }
  }, [])

  useEffect(() => {
    if (attendance) {
      handleInitDescription()
    }
  }, [attendance])

  return (
    <BaseCreatePage
      page="Atendimentos"
      path="Gerar NFSe"
      pageLink="/attendance"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.subTitle}>
            Complete os campos para gerar NFSe do serviço
          </Box>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <TextField
                  name="client_name"
                  value={form.client_name}
                  onChange={handleChange}
                  label="Nome do Tomador"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <ReactInputMask
                  mask={mask}
                  maskChar=""
                  name="cpf_cnpj"
                  value={form?.cpf_cnpj}
                  onChange={e => handleChangeCpf(e)}
                  onBlur={e => handleValidCpfCnpj(e)}
                  required
                >
                  {inputProps => (
                    <TextField
                      {...inputProps}
                      label="CPF/CNPJ"
                      fullWidth
                      error={cpfCnpj?.error}
                      helperText={cpfCnpj?.text}
                    />
                  )}
                </ReactInputMask>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <ReactInputMask
                      mask="99.999-999"
                      maskChar=""
                      name="client_cep"
                      value={form?.client_cep}
                      onChange={handleChange}
                      onBlur={() => handleSearchCep(form?.client_cep)}
                      required
                    >
                      {inputProps => (
                        <TextField {...inputProps} label="CEP" fullWidth />
                      )}
                    </ReactInputMask>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <TextField
                      name="client_street"
                      value={form.client_street}
                      onChange={handleChange}
                      label="Logradouro"
                      required
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      name="client_number"
                      value={form.client_number}
                      onChange={handleChange}
                      label="Número"
                      required
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      name="client_neighborhood"
                      value={form.client_neighborhood}
                      onChange={handleChange}
                      label="Bairro"
                      required
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      name="client_complement"
                      value={form.client_complement}
                      onChange={handleChange}
                      label="Complemento"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      name="client_city"
                      value={form.client_city}
                      label="Cidade"
                      required
                      fullWidth
                      disabled
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      name="client_uf"
                      value={form.client_uf}
                      label="Estado"
                      required
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <ReactInputMask
                  mask="(99) 99999-9999"
                  maskChar=""
                  name="client_phone"
                  value={form?.client_phone}
                  onChange={handleChange}
                >
                  {inputProps => (
                    <TextField {...inputProps} label="Telefone" fullWidth />
                  )}
                </ReactInputMask>
              </Grid>

              <Grid item xs={3}>
                <TextField
                  name="client_email"
                  value={form.client_email}
                  label="E-mail"
                  fullWidth
                  required
                  type="email"
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <InputBase
                  name="description"
                  value={form.description}
                  onChange={handleChange}
                  placeholder="Descrição"
                  multiline
                  required
                  inputProps={{
                    style: {
                      minHeight: '169px',
                      maxHeight: '200px',
                      overflow: 'auto',
                    },
                  }}
                  fullWidth
                  style={{
                    borderRadius: '10px',
                    border: `1px solid ${colors.graylight}`,
                    minHeight: '169px',
                    padding: '8px 0 8px 8px',
                    maxHeight: '200px',
                  }}
                />
              </Grid>

              <Grid item xs={9}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      label="Valor da Nota:"
                      required
                      value={form.value}
                      variant="outlined"
                      onChange={e =>
                        setForm({
                          ...form,
                          value: e.target.value,
                        })
                      }
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          prefix: 'R$ ',
                          style: {
                            textAlign: 'left',
                            color: colors.textPrimary,
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      select
                      value={form?.iss_retido}
                      name="iss_retido"
                      required
                      variant="outlined"
                      fullWidth
                      label="ISS Retido?"
                      onChange={handleChange}
                    >
                      <MenuItem value="">Selecione...</MenuItem>
                      <MenuItem value="true">Sim</MenuItem>
                      <MenuItem value="false">Não</MenuItem>
                    </TextField>
                  </Grid>

                  {form?.iss_retido == 'true' && (
                    <Grid item xs={4}>
                      <TextField
                        label="Aliquota ISS"
                        required
                        value={form.aliquota_iss}
                        variant="outlined"
                        onChange={e =>
                          setForm({
                            ...form,
                            aliquota_iss: e.target.value,
                          })
                        }
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          inputProps: {
                            suffix: '%',
                            style: {
                              textAlign: 'left',
                              color: colors.textPrimary,
                            },
                            min: 0,
                            max: 100,
                          },
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={3}
                container
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <IconButton
                  type="submit"
                  color={colors.primary}
                  variant="contained"
                  className={filterClasses.btnAdd}
                >
                  <Icon path={mdiInvoiceTextCheckOutline} size={1} />
                  Gerar
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </BaseCreatePage>
  )
}

export default EletronicInvoice
